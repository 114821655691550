// Advance tab Styles 
.faqs{
    margin-left: 50px;
    margin-right:50px;
}
.event-title{
    margin: 5px 0 0 0;
}
.event-date{
    border-radius: 10px;
    padding: 3px 3px 7px 3px !important;
    background-color: #e7f1f8 !important;
    width: 65px;
    h3{
        border-radius: 9px;
        margin: 1px 3px 1px 3px !important;
        font-size: 29px;
        background-color: #F4F8F7;
    }
    span{
        color: black;
        font-size: 10px;
        font-weight: 800;
    }
}
.event-background{
    background-color: #DBDBDB;
}
.news-text{
    line-height: 1.5rem;
    h4{
        font-size: 16px;
    }
    h5{
        margin-block-end: 0;
        margin-block-start: 0;
        font-size: 12px;
    }
    a {
        font-size: 11px;
    }

}
//.event-date{
//    border: 1px solid black;
//    padding: 2px;
//    border-radius: 5px;
//    min-width: 60px;
//    overflow: hidden;
//}
.headline-position{
    position: absolute;
    bottom: 0;
}
.headline-opacity{
    background-color: rgba(255, 255, 255, 0.8) !important;
    a{
        color:black !important;
    }
}
.featured-news-image{
    background-size: cover;
    background-repeat: no-repeat;
    height: 260px;
    width: 90%;
    @media #{$lg-layout}
    {
        height: 250px;
        width: 100%;
    }
    @media #{$latop-and-above} {
        height: 250px;
        width: 100%;
        overflow: hidden;
    }
}
.news-thumb{
    height: 68px;
    width: 100%;
    object-fit: cover;
}
.no-margin{
    margin: 0 !important;
}
.advance-tab-button-1 {
    .tab-button-list {
        list-style: none;
        padding: 0;
        margin: -10px 0;

        li {
            margin: 10px 0;
        }

        .tab-button {
            //cursor: pointer;
            //margin-bottom: 20px !important;
            //padding: 0px 0px 7px 7px;
            //padding: 0 !important;
            border-radius: 10px;
            position: relative;
            //z-index: 2;


            &::before {
                position: absolute;
                content: "";
                background: var(--color-blackest);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: 0.3s;
                opacity: 0;
                transform: scale(0.9);
                z-index: -1;
                border-radius: 10px;
            }

            &:hover {
                &::before {
                    opacity: 1;
                    transform: scale(1);
                }
            }


            //&::after {
            //    content: '';
            //    position: absolute;
            //    top: 50%;
            //    left: 100%;
            //    border-left: 20px solid var(--color-blackest);
            //    border-top: 20px solid transparent;
            //    border-bottom: 20px solid transparent;
            //    margin-top: -20px;
            //    opacity: 0;
            //    transition: 0.3s;
            //    transform: scale(0.9);
            //
            //    @media #{$md-layout} {
            //        display: none;
            //    }
            //    @media #{$sm-layout} {
            //        display: none;
            //    }
            //}

            .title {
                color: var(--color-body);
                font-size: 16px;
                margin-bottom: 10px;
            }

            .description {
                margin-bottom: 0;
                font-size: 16px;
            }
        }

        .react-tabs__tab--selected {
            .tab-button {
                .title {
                    color: var(--color-white);
                }
                &::before {
                    opacity: 1;
                    transform: scale(1);
                }

                &::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }


    }
}

.advance-tab-content-1 {
    .thumbnail {
        img {
            border-radius: 10px;
        }
    }
}

// Right Align

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
    transform: scale(0.9) rotate(-180deg);
    left: auto;
    right: 100%;
}
.advance-tab-button-1.right-align .tab-button-list .react-tabs__tab--selected .tab-button::after {
    transform: scale(1) rotate(-180deg);
}
.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
    left: 50%;
    transform: scale(0.9) translateX(-50%) rotate(45deg);
    bottom: 94%;
    top: auto;
}
.advance-tab-button-1.right-top .tab-button-list .react-tabs__tab--selected .tab-button::after {
    opacity: 1;
    transform: scale(1) translateX(-50%) rotate(-90deg);
}





