/*--------------------------
    Header Styles  
 --------------------------*/
.home-tagline{
    background: transparent !important;
    position: absolute;
    top: 60%;
    left: 55%;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    z-index: 0;
}
.search-page-search{
    height: 50px !important;
}
.inputWithButton{
    position: relative;
    height: 2px;
}
.input-button {
    position: absolute;
    right: 0;
    top : 10px;
    font-weight: 600;
    font-size: 14px;
}
 .searchfield{
     height: 55px;
 }
 .searchboxround{
     border-top-left-radius: 20px;
     border-bottom-left-radius: 20px;
 }
.searchbuttonround{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.logo-dark {
    display: none;
}

.header-default {
    max-width: 100%;
    //border-bottom: 1px solid var(--color-border);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;
    .logo {
        a {
            display: flex;
            height: 80px;
            line-height: 80px;
            align-items: center;
            img {
                max-height: 45px;
            }
        }
    }
    
    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.header-relative {
        position: relative;
    }
    .dropdown-link{
        background-color: white;
        width: 100%;
        border-bottom: 1px solid #ebebeb;
        box-shadow: none;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 14px;
        svg {
            vertical-align: -1.8px;
        }

        &:nth-last-child(1)
        {
            border-bottom: none;
        }
        &:hover{
            color: var(--button-color)
        }
    }
    #nav-dropdown{
        color: white;
        font-weight: bold;
        background-color: var(--button-color); //old: #8D1539
        border-color: var(--button-color); //old: #8D1539
        border-radius: 100px;
        border-width: 2px;
        height: 40px;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;

        .header-btn {
            margin-left: 25px;
            @media #{$md-layout} {
                margin-left: 0;
            }
            @media #{$sm-layout} {
                margin-left: 0;
            }
        }
    }
    a ,
    button {
        &.btn-default {
            @media #{$sm-layout} {
                height: 36px;
                line-height: 35px;
                padding: 0 16px;
                font-size: 12px;
            }
            &.btn-small {
                @media #{$sm-layout} {
                    height: 36px;
                    line-height: 35px;
                    padding: 0 16px;
                    font-size: 12px;
                }
            }
        }
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        //background: transparent !important;
        background-color: rgba(255,255,255,0.4) !important;
        backdrop-filter: inherit;
    }
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media #{$md-layout} {
        position: relative;
    }
    @media #{$sm-layout} {
        position: relative;
    }
    .header-top-bar {
        background-color: transparent;
        @media #{$md-layout} {
            background-color: var(--color-darker);
        }
        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }
    .header-default {
        background-color: transparent;
        background-image: none;
        @media #{$md-layout} {
            background-color: var(--color-darker);
        }
        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }
}



.hamberger-button {
    background: var(--color-blackest);
    border: 0 none;
    color: var(--color-white);
    width: 40px;
    height: 40px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.header-left-align {
    .mainmenu-nav {
        margin-left: 30px;
    }
}



.rn-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-dark) !important;
    width: 100%;
    height: 80px;
    animation: headerSticky .95s ease forwards;
    z-index: 9;
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
.category-icon-header{
    background-color: #F2F2F2;
    padding: 2px;
    border-radius: 2px;
}








