/*---------------------
    Tab Styles  
-----------------------*/

.rn-default-tab {

    .publication-padding{
        padding: 12px 22px !important;

    }
    .tab-button {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        .react-tabs__tab {
            //padding: 20px 44px;
            padding: 12px 22px !important;

            margin: 0;

            @media #{$lg-layout} {
                padding: 20px 24px;
            }

            @media #{$md-layout} {
                padding: 20px 24px;
            }

            @media #{$sm-layout} {
                padding: 20px 24px;
            }

            &.react-tabs__tab--selected {
                background: black;
            }
        }
        .rn-tab-button {
            button {
                padding: 0;
                margin: 0;
                background: transparent;
                border: 0 none;
                color: var(--color-white);
            }
        }
    }

    // Tab Content 
    .rn-tab-content {
        background: var(--color-lessdark);
        padding: 40px 40px;
        @media #{$sm-layout} {
            padding: 30px;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-two {
        .tab-button {
            margin-bottom: 30px;
            .react-tabs__tab {
                border-radius: var(--radius-small);
            }
        }
        .rn-tab-content {
            border-radius: var(--radius-small);
        }
    }
}