/**************************************
    Default Styles
***************************************/

@import 'bootstrap/dist/css/bootstrap.min.css';
// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';
@import 'default/custom';


/**************************************
    Theme Styles
***************************************/

@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';


/**************************************
    Elements Styles
***************************************/
@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/service';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}

.blog-search{
    top:10% !important;
}

.text-align-end{
    text-align: end;
}
.transformsup{
    transform: translate(-31px,-21px) !important;
}
// #google_translate_element 
// {
    
// }

.goog-te-combo{
    background-color: transparent !important;
    color: black !important;
    width: 100% !important;
}

// div.goog-te-gadget {
//     color: transparent !important;
// }

// #google_translate_element img
//         {
//             display: none !important;
//         }

.goog-logo-link {display:none !important; } 
.goog-te-gadget {font-size:0px !important;}